import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Head from './head';
import Header from './header';
import Email from './email';
import Footer from './footer';
import { nav } from '../config';
import { GlobalStyle } from '../styles';

class Layout extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.object.isRequired,
  };

  render() {
    const { children, location } = this.props;

    return (
      <StaticQuery
        query={graphql`
          query LayoutQuery {
            site {
              siteMetadata {
                title
                siteUrl
                description
              }
            }
          }
        `}
        render={({ site }) => (
          <div id="root">
            <Head metadata={site.siteMetadata} />
            <GlobalStyle />

            <div className="container">
              {location && nav && <Header location={location} navLinks={nav} />}
              <Email />
              {children}
              {/* <Footer /> */}
            </div>
          </div>
        )}
      />
    );
  }
}

export default Layout;
