module.exports = {
    siteTitle: 'Marko Arezina',
    siteDescription: '',
    siteUrl: 'https://markoarezina.com',
    siteLanguage: 'en_US',
    name: 'Marko Arezina',
    email: 'marko.arezina@uwaterloo.ca',
    socialMedia: [
        {
            name: 'Github',
            url: 'https://github.com/mrkarezina',
        },
        {
            name: 'Linkedin',
            url: 'https://www.linkedin.com/in/marko-arezina-b9ba16173/',
        },
        // {
        //     name: 'Twitter',
        //     url: 'https://twitter.com/mrkarezina',
        // }
    ],

    nav: [
        {
            name: 'Projects',
            url: '#projects',
        },
        // {
        //     name: 'About',
        //     url: '#about',
        // },
        {
            name: 'Contact',
            url: '#contact',
        },
    ],
    googleAnalyticsID: 'UA-136522661-1',
    srConfig: (delay = 200) => {
        return {
            origin: 'bottom',
            distance: '20px',
            duration: 300,
            delay,
            rotate: {x: 0, y: 0, z: 0},
            opacity: 0,
            scale: 1,
            easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
            mobile: true,
            reset: false,
            useDelay: 'always',
            viewFactor: 0.25,
            viewOffset: {top: 0, right: 0, bottom: 0, left: 0},
        };
    },
};
