const theme = {
  colors: {
    darkNavy: '#020c1b',
    white: '#ffffff',
    lightNavy: '#172a45',
    darkGrey: '#333f58',
    lightGrey: '#8d8d8d',  // Muted grey
    blueGrey: '#293d5a',
    mediumGrey: '#4c5772',
    black: '#131313',
    lightBlue: '#0f96ef', // Baby blue
    highlight: '#3ec4fb'
  },

  fonts: {
    Nunito: 'Nunito',
    NunitoSans: 'Nunito Sans',
  },

  fontSizes: {
    xsmall: '12px',
    smallish: '13px',
    small: '14px',
    medium: '16px',
    large: '18px',
    xlarge: '20px',
    xxlarge: '25px',
    h3: '32px',
  },

  easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
  transition: 'all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1)',

  borderRadius: '8px',
  headerHeight: '100px',
  headerScrollHeight: '70px',
  margin: '20px',

  tabHeight: 42,
  tabWidth: 120,

  gradient: `linear-gradient(0.4turn, #64d6ff, #64ffda)`,

  loaderDelay: `6`,

  hamburgerWidth: 30,
  hamBefore: `top 0.1s ease-in 0.25s, opacity 0.1s ease-in`,
  hamBeforeActive: `top 0.1s ease-out, opacity 0.1s ease-out 0.12s`,
  hamAfter: `bottom 0.1s ease-in 0.25s, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19)`,
  hamAfterActive: `bottom 0.1s ease-out, transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s`,
};

export default theme;
