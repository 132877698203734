import React, { Component } from 'react';
import {TransitionGroup } from 'react-transition-group';
import { email, socialMedia } from '../config';
import { IconGithub, IconLinkedin, IconInstagram, IconTwitter} from './icons';
import styled from 'styled-components';
import { theme, media } from '../styles';
const { colors, fontSizes, fonts } = theme;

const ChannelsContainer = styled.div`
  width: 40px;
  position: fixed;
  bottom: 0;
  right: 40px;
  color: ${colors.mediumGrey};
  ${media.desktop`right: 25px;`};
  ${media.tablet`display: none;`};
  div {
    width: 100%;
    margin: 0 auto;
  }
`;
const EmailLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  &:after {
    content: '';
    height: 90px;
  }
`;
const EmailLink = styled.a`
  font-family: ${fonts.Nunito};
  font-size: ${fontSizes.xsmall};
  letter-spacing: 0.5px;
  writing-mode: vertical-rl;
  margin: 20px auto;
  padding: 10px;
`;

const SocialItemList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SocialItem = styled.li`
  &:last-of-type {
    margin-bottom: 20px;
  }
`;
const SocialLink = styled.a`
  padding: 10px;
  svg {
    width: 18px;
    height: 18px;
  }
`;

class Email extends Component {

  render() {

    return (
      <ChannelsContainer>
        <TransitionGroup>
          <SocialItemList>
            {socialMedia &&
              socialMedia.map(({ url, name }, i) => (
                <SocialItem key={i}>
                  <SocialLink
                    href={url}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                    aria-label={name}>
                    {name === 'Github' ? (
                      <IconGithub />
                    ) : name === 'Linkedin' ? (
                      <IconLinkedin />
                    ) : name === 'Instagram' ? (
                      <IconInstagram />
                    ) : name === 'Twitter' ? (
                      <IconTwitter />
                    ) : (
                      <IconGithub />
                    )}
                  </SocialLink>
                </SocialItem>
              ))}
          </SocialItemList>

          <EmailLinkWrapper>
            <EmailLink href={`mailto:${email}`}>{email}</EmailLink>
          </EmailLinkWrapper>
        </TransitionGroup>
      </ChannelsContainer>
    );
  }
}

export default Email;
