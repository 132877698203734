import { css } from 'styled-components';
import theme from './theme';
import media from './media';
const { colors, fontSizes, fonts } = theme;

const mixins = {
  flexCenter: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,

  flexBetween: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,

  outline: css`
    outline: 1px solid red;
  `,

  link: css`
    display: inline-block;
    text-decoration: none;
    text-decoration-skip-ink: auto;
    color: inherit;
    position: relative;
    transition: ${theme.transition};
    cursor: pointer;
    &:hover,
    &:active,
    &:focus {
      color: ${colors.lightBlue};
      outline: 0;
    }
  `,

  inlineLink: css`
    position: relative;
    text-decoration: none;
    color: ${colors.lightBlue};
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: -1px;
      left: 0;
      background-color: ${colors.lightBlue};
      visibility: hidden;
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transition: all 0.3s ease-in-out 0s;
      transition: ${theme.transition};
    }
    &:hover:before {
      visibility: visible;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
  `,

  externalIconLink: css`
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 10px;
    margin-left: -10px;
    a {
      padding: 10px;
      svg {
        width: 22px;
        height: 22px;
      }
    }
  `,

  smallButton: css`
   display: relative;
   padding:0.5em 3em;
   border: 0.12em solid ${colors.lightBlue};
   border-radius: ${theme.borderRadius};
   padding: 5px 20px;
   box-sizing: border-box;
   text-decoration:none;
   font-family: ${fonts.Nunito};
   font-weight: 400;
   color: ${colors.lightBlue};
   text-align:center;
   transition: ${theme.transition};
    &:hover,
    &:focus,
    &:active {
      background-color: ${colors.lightBlue};
      color: ${colors.white}
    }
    &:after {
      display: none !important;
    }
  `,


  bigButton: css`
   display: relative;
   padding:0.5em 3em;
   border:0.12em solid ${colors.lightBlue};
   border-radius: ${theme.borderRadius};
   margin:0 0.3em 0.3em 0;
   box-sizing: border-box;
   text-decoration:none;
   font-family: ${fonts.Nunito};
   font-weight: 500;
   color: ${colors.lightBlue};
   text-align:center;
   transition: ${theme.transition};
    &:hover {
      background-color: ${colors.lightBlue};
      color: ${colors.white}
    }
  `,

  sidePadding: css`
    padding: 0 150px;
    ${media.desktop`padding: 0 100px;`};
    ${media.tablet`padding: 0 50px;`};
    ${media.phablet`padding: 0 25px;`};
  `,
};

export default mixins;
